import React from "react"
import styled from "styled-components"

import * as TextStyles from "../styles/TextStyles"

interface Props {
  error: string
}

interface StyledProps {
  messageNbOfLines: number
}

const ErrorBubble = ({ error }: Props) => {
  const messageNbOfLines = Math.round(error.length / 30)

  return (
    <Wrapper messageNbOfLines={messageNbOfLines}>
      <ContentWrapper messageNbOfLines={messageNbOfLines}>
        <Caption>{error}</Caption>
      </ContentWrapper>
    </Wrapper>
  )
}

export default ErrorBubble

const Wrapper = styled.div<StyledProps>`
  width: fit-content;
  border-radius: 10px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.9);

  border-radius: 35px;
  border: 1px solid rgba(255, 255, 255, 0.6);

  position: absolute;
  top: -2.5%;
  left: 50%;
  transform: translate(-50%);
`

const ContentWrapper = styled.div<StyledProps>`
  padding: 10px;
`

const Caption = styled(TextStyles.Caption)`
  color: #8338a8;
`
