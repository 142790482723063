import React from "react"
import { createClient } from "contentful"

import CombinedProvider from "./src/states/CombinedProvider"
import ModalRoute from "./src/components/layout/ModalRoute"
import config from "./src/utils/config"
import { mockupsCount } from "./src/data/MockupsData"

export const wrapRootElement = CombinedProvider

export const wrapPageElement = ({ element, props }) => {
  return <ModalRoute {...props}>{element}</ModalRoute>
}

export const onClientEntry = async () => {
  const client = createClient(config.contentful)

  const count = {
    total: 0
  }

  try {
    const oses = ["iOS", "iPadOS", "macOS", "Android"]

    await Promise.all(
      oses.map(async (os) => {
        const entries = await client.getEntries({
          content_type: "mockup",
          select: "fields.images",
          "fields.os": os,
          limit: 1000,
        })

        const assetsCount = entries.includes["Asset"].length

        count[os] = {
          assets: assetsCount,
          entries: entries.total
        }
        count.total += assetsCount
        mockupsCount[os] = assetsCount
      })
    )

    mockupsCount.total = count.total
    console.log(`count`, count)
  } catch (error) {
    console.error(`Error fetching count from Contentful: `, error)
  }
}