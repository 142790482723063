module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-S42Z6SF8RK","UA-151640385-1"],"pluginConfig":{"head":true,"custom_map":{"dimension1":"clientId","dimension2":"userId"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"angle4","short_name":"angle","start_url":"/","background_color":"#6E50D3","theme_color":"#6E50D3","display":"minimal-ui","icon":"src/images/logos/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cca4017a7b42c224eb84d080a781f577"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
