import React from "react"
import styled from "styled-components"

import { useDismissModal } from "../../states/context/ModalContext"

const CloseButton = () => {
  const dismiss = useDismissModal()

  return (
    <Wrapper onClick={dismiss}>
      <Icon src="/images/icons/close.svg" alt="close icon" />
    </Wrapper>
  )
}

export default CloseButton

const Wrapper = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(227, 225, 255, 0.5);
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.13), 0px 0px 3px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 50%;
  cursor: pointer;
`

const Icon = styled.img`
  margin: auto;
  width: 12px;
  height: 12px;
`
