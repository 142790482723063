import React, { ReactNode, createContext, useContext, useReducer, useEffect } from "react"

import subscriptionReducer, { initialState, State, Actions } from "./subscriptionReducer"
import useAuth from "./AuthContext"
import useFetchFunctions, { FunctionsEndpoint } from "../../utils/hooks/useFetchFunctions"
import Helpers from "../../utils/Helpers"
import { freeLibraries } from "../../data/libraryData"

import { ContextStatus, initialContextStatus } from "../../types/statusTypes"
import { CurrentUser } from "../../types/authTypes"

type ContextProps = {
  children: ReactNode
}

type ContextValue = State & {
  getSubscriptionStatus: () => void
  getLibraries: (currentUser: CurrentUser) => void
  renewSubscription: () => void
}

const SubscriptionContext = createContext<ContextValue | undefined>(undefined)

export function SubscriptionProvider({ children }: ContextProps) {
  const [state, dispatch] = useReducer(subscriptionReducer, initialState)

  const { isAuthLoading, currentUser } = useAuth()
  const { fetchFunctions } = useFetchFunctions()

  useEffect(() => {
    receivePromoCode()
    !isAuthLoading && getSubscriptionStatus()
  }, [currentUser])

  const getSubscriptionStatus = async () => {
    // console.log("Get subscription status", currentUser)

    if (currentUser) {
      setStatus({
        state: "pending",
      })

      const response = await fetchFunctions(FunctionsEndpoint.accountStatus, {
        currentUser,
      })

      if (response.status === "success") {
        dispatch({
          type: Actions.setSubscriptionStatus,
          payload: {
            subscriptionStatus: response.payload,
          },
        })

        getLibraries(currentUser)

        setStatus({
          state: "resolved",
          message: "SubscriptionStatus fetched",
        })
      }

      if (response.status === "fail") {
        setStatus({
          state: "rejected",
          error: response.error,
        })
      }
      resetStatus()
    } else {
      dispatch({
        type: Actions.setSubscriptionStatus,
        payload: {
          subscriptionStatus: undefined,
        },
      })
    }
  }

  const getLibraries = async (currentUser: CurrentUser) => {
    setStatus({
      state: "pending",
    })

    if (currentUser) {
      const response = await fetchFunctions(FunctionsEndpoint.downloadLinksGet, { currentUser })

      if (response.status === "success") {
        const libraries = response.payload.libraries

        dispatch({
          type: Actions.setLibraries,
          payload: {
            libraries,
          },
        })
      }

      if (response.status === "fail") {
        setStatus({
          state: "rejected",
          error: response.error,
        })
      }
    } else {
      dispatch({
        type: Actions.setLibraries,
        payload: {
          libraries: {
            free: freeLibraries,
            pro: undefined,
          },
        },
      })
    }
  }

  const receivePromoCode = () => {
    let query = Helpers.extractQueryFromUrl("promo")

    // Accepts only promo code producthunt from ref query
    // if (!query) {
    //   query = Helpers.extractQueryFromUrl("ref")
    //   if (query && query !== "producthunt") return
    // }

    // If promo code
    if (query) {
      // If promo code is within accepted values
      const promoCode = query.toLowerCase()

      const validPromoCodes = ["designcode", "shape"]
      const isPromoCodeValid = validPromoCodes.indexOf(promoCode) !== -1
      // const isPromoCodeValid = validPromoCodes.includes(promoCode); // Doesn't work for IE

      if (isPromoCodeValid) {
        dispatch({
          type: Actions.setPromo,
          payload: {
            promo: {
              isPromo: true,
              promoCode,
            },
          },
        })
      }
    }
  }

  const renewSubscription = () => {
    if (!currentUser) throw new Error("renewSubscriptioon cannot be used without a current user")
    if (!state.subscriptionStatus?.subscription)
      throw new Error("renewSubscriptioon cannot be used without a subscription")
    if (state.subscriptionStatus?.subscriptionCase !== "subscriptionCancelled")
      throw new Error("renewSubscriptioon cannot be used without an expired subscription")

    dispatch({
      type: Actions.setPromo,
      payload: {
        promo: {
          isPromo: true,
          promoCode: "L1REVY",
        },
      },
    })
  }

  const resetStatus = () => {
    setStatus(initialContextStatus)
  }

  const setStatus = (status: ContextStatus) => {
    const { state, message, error, showMessage } = status

    if (state === "resolved") {
      // console.log(message)
    }

    if (state === "rejected") {
      console.log(error)
    }

    dispatch({
      type: Actions.setStatus,
      payload: {
        status: {
          state,
          message: message || null,
          error: error || null,
          isPending: state === "pending",
          isResolved: state === "resolved",
          isRejected: state === "rejected",
          showMessage: showMessage || false,
        },
      },
    })
  }

  const value = {
    isSubscriptionLoading: state.isSubscriptionLoading,
    subscriptionStatus: state.subscriptionStatus,
    isAdmin: state.isAdmin,
    isProUser: state.isProUser,
    isFreeUser: state.isFreeUser,
    isFormerProUser: state.isFormerProUser,
    promo: state.promo,
    libraries: state.libraries,
    status: state.status,
    getSubscriptionStatus,
    getLibraries,
    renewSubscription,
  }

  return <SubscriptionContext.Provider value={value}>{children}</SubscriptionContext.Provider>
}

// Custom hook to use SubscriptionContext
const useSubscription = () => {
  const context = useContext(SubscriptionContext)

  if (context === undefined) {
    throw new Error("useSubscription must be used within an SubscriptionContext")
  }

  return context
}

export default useSubscription
