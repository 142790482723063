import React, {
  ReactNode,
  useState,
  createContext,
  useContext,
  useCallback,
} from "react"

import Modal, { ModalType } from "../../modals/Modal"

type ContextProps = {
  children: ReactNode
}

type ShowModalContextValue = (modalType: ModalType, modalProps?: any) => void
type DismissModalContextValue = () => void

const ShowModalContext = createContext<ShowModalContextValue | undefined>(
  undefined
)
const DismissModalContext = createContext<DismissModalContextValue | undefined>(
  undefined
)

export const ModalProvider = ({ children }: ContextProps) => {
  const [show, setShow] = useState(false)
  const [type, setType] = useState<ModalType | undefined>(undefined)
  const [props, setProps] = useState<any | undefined>(undefined)

  const showModal = useCallback((modalType: ModalType, modalProps?: any) => {
    setShow(true)
    setType(modalType)
    setProps(modalProps)
  }, [])

  const dismissModal = useCallback(() => {
    setShow(false)
    setType(undefined)
    setProps(undefined)
  }, [])

  return (
    <>
      <ShowModalContext.Provider value={showModal}>
        <DismissModalContext.Provider value={dismissModal}>
          <Modal isVisible={show} type={type} {...props} />
          {children}
        </DismissModalContext.Provider>
      </ShowModalContext.Provider>
    </>
  )
}

export const useShowModal = (type: ModalType, props?: any) => {
  const context = useContext(ShowModalContext)

  if (context === undefined) {
    throw new Error("useShowModal must be used in a ShowModalContext")
  }

  return () => context(type, props)
}

export const useDismissModal = () => {
  const context = useContext(DismissModalContext)

  if (context === undefined) {
    throw new Error("useDismissModal must be used in a DismissModalContext")
  }

  return context
}
