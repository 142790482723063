import styled from "styled-components"
import { themes } from "./ColorStyles"

export const H1 = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  margin: 0;
  color: ${themes.light.text1};

  @media (max-width: 414px) {
    font-size: 34px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text1};
  }
`

export const H2 = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  margin: 0;
  color: ${themes.light.text1};

  @media (max-width: 414px) {
    font-size: 28px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text1};
  }
`

export const H1H2 = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  margin: 0;
  color: ${themes.light.text1};

  @media (max-width: 414px) {
    font-size: 28px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text1};
  }
`

export const H3 = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  margin: 0;
  color: ${themes.light.text1};

  @media (max-width: 414px) {
    font-size: 28px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text1};
  }
`

export const H4 = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  margin: 0;
  color: ${themes.light.text1};

  @media (max-width: 414px) {
    font-size: 24px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text1};
  }
`

export const H5 = styled.h5`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  color: ${themes.light.text1};

  @media (max-width: 414px) {
    font-size: 24px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text1};
  }
`

export const H3H5 = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  color: ${themes.light.text1};

  @media (max-width: 414px) {
    font-size: 24px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text1};
  }
`

export const BodyIntro = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  margin: 0;
  color: ${themes.light.text1};

  @media (max-width: 414px) {
    font-size: 20px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text1};
  }
`

export const BodyMain = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  margin: 0;
  color: ${themes.light.text1};

  @media (max-width: 414px) {
    font-size: 17px;
  }
  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text1};
  }
`

export const MediumText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 130%;
  margin: 0;
  color: ${themes.light.text1};

  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text1};
  }
`

export const Caption = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
  color: ${themes.light.text2};

  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text2};
  }
`

export const Caption2 = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  margin: 0;
  color: ${themes.light.text2};

  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text2};
  }
`

export const SmallText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
  margin: 0;
  color: ${themes.light.text2};

  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text2};
  }
`

export const SmallText2 = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 130%;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  color: ${themes.light.text2};

  @media (prefers-color-scheme: dark) {
    color: ${themes.dark.text2};
  }
`
