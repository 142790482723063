import React from "react"
import "@stripe/stripe-js"

import { AuthProvider } from "./context/AuthContext"
import { ModalProvider } from "./context/ModalContext"
import { SubscriptionProvider } from "./context/SubscriptionContext"

interface Props {
  element: React.ReactNode
}

const CombinedProvider = ({ element }: Props) => {
  return (
    <AuthProvider>
      <SubscriptionProvider>
        <ModalProvider>{element}</ModalProvider>
      </SubscriptionProvider>
    </AuthProvider>
  )
}

export default CombinedProvider
