import React from "react"
import styled from "styled-components"

interface Props {
  onClick?: () => void
  icon: string
  isLeft?: boolean
}

interface StyledProps {
  isLeft?: boolean
}

const PurpleButton = (props: Props) => {
  const { onClick, icon, isLeft, ...rest } = props

  return (
    <Wrapper className="purple-button" onClick={onClick} isLeft={isLeft}>
      <Icon src={icon} alt="icon" />
    </Wrapper>
  )
}

export default PurpleButton

const Wrapper = styled.div<StyledProps>`
  display: grid;
  justify-content: center;
  position: absolute;
  left: ${props => (props.isLeft ? "calc(4% + 1px)" : "") || ""};
  right: ${props => (props.isLeft ? "" : "calc(4% + 1px)") || ""};
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 5;
  background: linear-gradient(180deg, rgba(131, 56, 168, 0.3) 0%, rgba(31, 17, 68, 0.3) 97.27%);
  border: 0.2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0px 10px 20px #c6bbd4, inset 1px 1px 2px rgba(255, 255, 255, 0.5),
    inset 1px 1px 2px 1px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);

  &,
  * {
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: scale(1.05);
  }

  @media (prefers-color-scheme: dark) {
    background: linear-gradient(180deg, rgba(0, 198, 242, 0.3) 0%, rgba(31, 17, 68, 0.3) 97.27%);
  }
`

const Icon = styled.img`
  width: 16px;
  height: auto;
  margin: auto;
  opacity: 0.4;
`
