import { globalHistory } from "@reach/router"

const isLive = process.env.NODE_ENV === "production"

interface Config {
  baseUrl: string
  hostname: string
  contentful: {
    space: string
    environment: string
    accessToken: string
    host?: string | undefined
  }
  firebase: {
    apiKey: string
    authDomain: string
    databaseURL: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    measurementId: string | undefined
  }
  firebaseFunctionsUrl: string
  stripeKey: string
  paypalClientId: string
}

const config: Config = {
  baseUrl: globalHistory.location.origin,
  hostname: globalHistory.location.hostname,
  contentful: {
    space: process.env.GATSBY_CONTENTFUL_SPACE_ID!,
    environment: "master",
    accessToken: isLive
      ? process.env.GATSBY_CONTENTFUL_DELIVERY_TOKEN!
      : process.env.GATSBY_CONTENTFUL_PREVIEW_TOKEN!,
    host: isLive ? "cdn.contentful.com" : "preview.contentful.com",
  },
  firebase: {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY!,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN!,
    databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL!,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID!,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET!,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID!,
    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
  },
  firebaseFunctionsUrl: process.env.GATSBY_FIREBASE_FUNCTIONS_URL!,
  stripeKey: process.env.GATSBY_STRIPE_KEY!,
  paypalClientId: process.env.GATSBY_PAYPAL_CLIENT_ID!,
}

export default config
