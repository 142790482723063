// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-collections-android-tsx": () => import("./../../../src/pages/collections/android.tsx" /* webpackChunkName: "component---src-pages-collections-android-tsx" */),
  "component---src-pages-collections-desktop-tsx": () => import("./../../../src/pages/collections/desktop.tsx" /* webpackChunkName: "component---src-pages-collections-desktop-tsx" */),
  "component---src-pages-collections-ios-tsx": () => import("./../../../src/pages/collections/ios.tsx" /* webpackChunkName: "component---src-pages-collections-ios-tsx" */),
  "component---src-pages-downloads-tsx": () => import("./../../../src/pages/downloads.tsx" /* webpackChunkName: "component---src-pages-downloads-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mockups-index-tsx": () => import("./../../../src/pages/mockups/index.tsx" /* webpackChunkName: "component---src-pages-mockups-index-tsx" */),
  "component---src-pages-mockups-tsx": () => import("./../../../src/pages/mockups/[...].tsx" /* webpackChunkName: "component---src-pages-mockups-tsx" */),
  "component---src-pages-payment-cancel-tsx": () => import("./../../../src/pages/payment/cancel.tsx" /* webpackChunkName: "component---src-pages-payment-cancel-tsx" */),
  "component---src-pages-payment-success-tsx": () => import("./../../../src/pages/payment/success.tsx" /* webpackChunkName: "component---src-pages-payment-success-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-tutorials-tsx": () => import("./../../../src/pages/tutorials.tsx" /* webpackChunkName: "component---src-pages-tutorials-tsx" */),
  "component---src-pages-updates-tsx": () => import("./../../../src/pages/updates.tsx" /* webpackChunkName: "component---src-pages-updates-tsx" */)
}

