import { ContextStatus, initialContextStatus } from "../../types/statusTypes"
import { AccountStatus } from "../../types/subscriptionTypes"
import { Library } from "../../types/downloadTypes"
import { freeLibraries } from "../../data/libraryData"

export interface State {
  isSubscriptionLoading: boolean
  subscriptionStatus: AccountStatus | undefined
  isAdmin: boolean
  isProUser: boolean
  isFreeUser: boolean
  isFormerProUser: boolean
  promo: {
    isPromo: boolean
    promoCode: string | undefined
  }
  libraries: {
    free: Library
    pro: Library | undefined
  }
  status: ContextStatus
}

export enum Actions {
  setSubscriptionStatus = "SET_SUBSCRIPTION_STATUS",
  setLibraries = "SET_LIBRARIES",
  setPromo = "SET_PROMO",
  setStatus = "SET_STATUS",
}

interface Action {
  type: Actions
  payload: Partial<State> | any
}

export const initialState: State = {
  isSubscriptionLoading: true,
  subscriptionStatus: undefined,
  isAdmin: false,
  isProUser: false, // isAdmin || seated
  isFreeUser: true, //!isProUser && !isTrialUser
  isFormerProUser: false,
  promo: {
    isPromo: false,
    promoCode: undefined,
  },
  libraries: {
    free: freeLibraries,
    pro: undefined,
  },
  status: initialContextStatus,
}

const subscriptionReducer = (state: State, action: Action): State => {
  const { type, payload } = action

  switch (type) {
    case Actions.setSubscriptionStatus:
      // console.log("SET_SUBSCRIPTION_STATUS", payload)

      const { subscriptionStatus } = payload

      if (subscriptionStatus) {
        const { isAdmin, isProUser, isFreeUser, subscriptionCase } = subscriptionStatus
        const isFormerProUser =
          subscriptionCase === "subscriptionCancelled" || subscriptionCase === "purchaseExpired"

        return {
          ...state,
          isSubscriptionLoading: false,
          subscriptionStatus,
          isAdmin,
          isProUser,
          isFreeUser,
          isFormerProUser,
        }
      } else {
        // Set back to initial state except for status
        const { isSubscriptionLoading, status, ...rest } = initialState

        return {
          ...state,
          isSubscriptionLoading: false,
          ...rest,
        }
      }

    case Actions.setLibraries:
      // console.log("SET_LIBRARIES")

      return {
        ...state,
        libraries: payload.libraries,
      }

    case Actions.setPromo:
      // console.log("SET_PROMO", payload)

      return {
        ...state,
        isSubscriptionLoading: false,
        promo: payload.promo!,
      }

    case Actions.setStatus:
      // console.log("SET_STATUS", payload)

      return {
        ...state,
        status: payload.status!,
      }

    default:
      throw new Error(`No case for type ${type} found in subscriptionReducer.`)
  }
}

export default subscriptionReducer
