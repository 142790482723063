import React from "react"
import styled from "styled-components"

const LoaderAnimation = () => {
  return <Loader />
}

export default LoaderAnimation

const Loader = styled.div`
  border: 3px solid rgba(24, 32, 79, 0.4);
  border-top: 3px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
  z-index: 1;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
