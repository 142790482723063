import { Library } from "../types/downloadTypes"

// Fallback to free libraries if backend function downloadLinksGet fails
// To easily update this harcoded data, can run that function via Postman using a free current user in request body
export const freeLibraries: Library = {
  sample: {
    downloadId: "6JRXkzzLUqLqIaf8oOzyh",
    title: "Free Sample 4.0",
    version: 4,
    startDate: new Date("2021-10-26T15:00:00.000Z"),
    lastUpdated: new Date("2021-10-26T11:00-04:00"),
    link: "https://www.dropbox.com/sh/p5ym69ddi7rjes7/AACJnxRDVCDuEfU8LyvOImZza?dl=1",
    isFree: true,
    category: "mockup",
  },
  plugin: {
    downloadId: "54zAj3dQWx0KWUJetR0aV4",
    title: "Free Plugin",
    version: 3,
    startDate: new Date("2020-02-28T05:00:00.000Z"),
    lastUpdated: new Date("2020-02-28T00:00-05:00"),
    link: "https://www.dropbox.com/s/0s2bnvjiv6u4005/download%20Angle%20Free%20%281%29.zip?dl=1",
    isFree: true,
    category: "plugin",
  },
  wallpaper: {
    downloadId: "717eozvo4Prg4wpmzhNBaD",
    title: "Free Wallpaper",
    version: 3,
    startDate: new Date("2020-02-28T05:00:00.000Z"),
    lastUpdated: new Date("2020-02-28T00:00-05:00"),
    link: "https://www.dropbox.com/s/vlzap37hl081ff1/Free%20Wallpaper%20Sample.zip?dl=1",
    isFree: true,
    category: "wallpaper",
  },
}
