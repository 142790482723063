import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import * as TextStyles from "../components/styles/TextStyles"
import { themes } from "../components/styles/ColorStyles"
import { useShowModal, useDismissModal } from "../states/context/ModalContext"
import useAuth from "../states/context/AuthContext"
import FormInput from "../components/inputs/FormInput"
import useInput from "../utils/hooks/useInput"
import FormButton from "../components/buttons/FormButton"
import CloseButton from "../components/buttons/CloseButton"
import ErrorBubble from "../components/other/ErrorBubble"

const SignInModal = () => {
  const showSignUpModal = useShowModal("signUp")
  const showResetPasswordModal = useShowModal("resetPassword")
  const dismiss = useDismissModal()
  const { signInWithPassword, status } = useAuth()

  const { value: email, bind: bindEmail } = useInput("")
  const { value: password, bind: bindPassword } = useInput("")
  const [error, setError] = useState("")

  const handleSignInWithPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    signInWithPassword(email, password)
  }

  if (status.isResolved) {
    dismiss()
  }

  useEffect(() => {
    if (status.isRejected) {
      status.error && setError(status.error)
    }
  }, [status])

  return (
    <Wrapper>
      <CloseButtonContainer>
        <CloseButton />
      </CloseButtonContainer>
      {error !== "" && <ErrorBubble error={error} />}
      <Container>
        <Logo src="/images/logos/logo.svg" alt="angle logo" />
        <StaticImage
          className="illustration"
          src="../images/illustrations/illustration1.webp"
          alt="illustration"
        />
        <Title>Sign In</Title>
      </Container>
      <FormContainer onSubmit={handleSignInWithPassword}>
        <InputContainer>
          <Title2>Email address</Title2>
          <FormInput
            name="email"
            type="email"
            placeholder="Email address"
            icon="/images/icons/envelope.svg"
            autoComplete="on"
            required
            {...bindEmail}
          />
        </InputContainer>
        <InputContainer>
          <Title2>Password</Title2>
          <FormInput
            name="password"
            type="password"
            placeholder="Password"
            icon="/images/icons/password.svg"
            autoComplete="on"
            {...bindPassword}
            required
          />
        </InputContainer>
        <ButtonContainer>
          <FormButton
            title="Sign in"
            type="submit"
            isLoading={status.isPending}
            disabled={status.isPending}
          />
        </ButtonContainer>
      </FormContainer>
      <TextContainer>
        <Caption>
          New to Angle? <span onClick={showSignUpModal}>Sign Up</span>
        </Caption>
        <Caption>
          Forgot password? <span onClick={showResetPasswordModal}>Reset Password</span>
        </Caption>
      </TextContainer>
    </Wrapper>
  )
}

export default SignInModal

export const Wrapper = styled.div`
  position: relative;
  display: grid;
  max-width: 725px;
  max-height: 750px;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  gap: 30px;
  padding: 44px 0 37px;
  background: ${themes.light.modal.backgroundColor};
  backdrop-filter: blur(60px);
  border-radius: 35px;
  border: 2px solid rgba(255, 255, 255, 0.6);

  @media (prefers-color-scheme: dark) {
    background: ${themes.dark.modal.backgroundColor};
    border: 2px solid rgba(255, 255, 255, 0.2);
  }
`

export const Container = styled.div`
  display: grid;
  gap: 15px;

  .illustration {
    max-width: 266px;
    margin: 0 auto;
  }
`

export const TextContainer = styled.div`
  display: grid;
  max-width: 440px;
  width: 100%;
  gap: 5px;
`

export const Logo = styled.img`
  width: 32px;
  height: 32px;
  margin: 0 auto;
`

export const Title = styled(TextStyles.H4)``

export const Title2 = styled(TextStyles.BodyMain)`
  text-align: left;
`

export const Caption = styled(TextStyles.SmallText)`
  span {
    color: #8338a8;
    cursor: pointer;

    @media (prefers-color-scheme: dark) {
      color: rgba(255, 255, 255, 0.6);
    }
  }
`

export const FormContainer = styled.form`
  display: grid;
  max-width: 440px;
  width: 100%;
  gap: 30px;
  margin: 0;
`

export const InputContainer = styled.div`
  display: grid;
  width: 100%;
  gap: 9px;
`

export const ButtonContainer = styled.div`
  max-width: 150px;
`

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 13px;
  right: 22px;
`
