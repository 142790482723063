import { detect } from "detect-browser"

const browser = detect()

let isBlurSupported = true
let isSafari = false

if (browser && browser.name === "firefox") {
  isBlurSupported = false
}
if (browser && browser.name === "safari") {
  isSafari = true
}

export const themes = {
  light: {
    text1: `rgba(0,0,0,1)`,
    text2: `rgba(0,0,0,0.7)`,
    primary: `#3913B8`,
    secondary: "#2FB5FC",
    backgroundColor: `#f2f6ff`,
    card: {
      backgroundColor: `${
        isBlurSupported ? `rgba(255, 255, 255, 0.6)` : `rgba(255, 255, 255, 0.9)`
      }`,
      boxShadow: `${
        isSafari
          ? `0px 50px 100px rgba(34, 79, 169, 0.3),
      inset 0 0 0 0.5px rgba(255, 255, 255, 0.6)`
          : `inset 0 0 0 0.5px rgba(0, 0, 0, 0.1)`
      }`,
      backgroundColorFull: `rgba(255, 255, 255, 1)`,
    },
    cardHover: {
      backgroundColor: `rgba(68, 66, 178, 0.1)`,
      boxShadow: `inset 0px 0px 0px 0.5px rgba(68, 66, 178, 0.2)`,
    },
    pricingCard: {
      backgroundColor: `${
        isBlurSupported ? `rgba(255, 255, 255, 0.3)` : `rgba(255, 255, 255, 0.95)`
      }`,
    },
    status: {
      backgroundColor: "rgba(68, 66, 178, 0.1)",
    },
    modal: {
      backgroundColor: `${
        isBlurSupported ? `rgba(255, 255, 255, 0.6)` : `rgba(255, 255, 255, 0.95)`
      }`,
      boxShadow: `${
        isSafari
          ? `0px 50px 100px rgba(34, 79, 169, 0.3),
      inset 0 0 0 0.5px rgba(255, 255, 255, 0.6)`
          : `inset 0 0 0 0.5px rgba(255, 255, 255, 0.6)`
      }`,
      backgroundColorFull: `rgba(255, 255, 255, 1)`,
    },
  },
  dark: {
    text1: `rgba(255,255,255,1)`,
    text2: `rgba(255,255,255,0.7)`,
    primary: `#8352FD`,
    secondary: "#00CFFD",
    backgroundColor: `#1F1144`,
    card: {
      backgroundColor: `${isBlurSupported ? `rgba(15, 14, 71, 0.3)` : `rgba(25, 24, 63, 0.98)`}`,
      boxShadow: `${
        isSafari
          ? `0px 30px 60px rgba(0, 0, 0, 0.25),
      inset 0 0 0 0.5px rgba(255, 255, 255, 0.2)`
          : `inset 0 0 0 0.5px rgba(255, 255, 255, 0.2)`
      }`,
      backgroundColorFull: `rgba(15, 14, 71, 1)`,
    },
    cardHover: {
      backgroundColor: `rgba(255, 255, 255, 0.1)`,
      boxShadow: `inset 0px 0px 0px 0.5px rgba(255, 255, 255, 0.2)`,
    },
    pricingCard: {
      backgroundColor: `${isBlurSupported ? `rgba(31, 31, 71, 0.6)` : `rgba(25, 24, 63, 0.98)`}`,
    },
    status: {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    modal: {
      backgroundColor: `${isBlurSupported ? `rgba(50, 61, 109, 0.5)` : `rgba(25, 24, 63, 0.98)`}`,
      boxShadow: `${
        isSafari
          ? `0px 50px 100px rgba(0, 0, 0, 0.25),
      inset 0 0 0 0.5px rgba(255, 255, 255, 0.3)`
          : `inset 0 0 0 0.5px rgba(255, 255, 255, 0.3)`
      }`,
      backgroundColorFull: `rgba(15, 14, 71, 1)`,
    },
  },
}
