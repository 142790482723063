import React from "react"
import styled from "styled-components"

import * as TextStyles from "../styles/TextStyles"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string
  icon?: string
  onClick?: () => void
}

const PillButton = (props: Props) => {
  const { icon, title, onClick } = props

  return (
    <Wrapper onClick={onClick}>
      <Icon src={icon} />
      <Title>{title}</Title>
    </Wrapper>
  )
}

export default PillButton

const Wrapper = styled.button`
  display: flex;
  width: fit-content;
  height: 40px;
  padding: 10px 40px;
  margin: 0 auto;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.6) 100%);
  box-shadow: 0px 10px 20px rgba(69, 42, 124, 0.15);
  border-radius: 12px;
  cursor: pointer;
  border: none;

  &,
  * {
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    transform: translateY(-2px) scale(1.05);
  }
`

const Icon = styled.img`
  margin: auto;
  padding-right: 4px;
`

const Title = styled(TextStyles.Caption)`
  color: #8338a8;
  height: fit-content;
  margin: auto;
`
