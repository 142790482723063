import { useState, useCallback } from "react"

const useInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue)

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(event.target.value.trimStart())
    },
    []
  )

  const reset = useCallback(() => setValue(""), [])

  const hasValue = value !== undefined && value !== null && value.trim() !== ""

  return {
    value: value.trim(),
    setValue,
    onChange,
    reset,
    hasValue,
    bind: {
      value,
      onChange,
    },
  }
}

export default useInput
