import React, { useEffect, useState } from "react"

import { useShowModal } from "../../states/context/ModalContext"
import { ModalType, modalTypeArray } from "../../modals/Modal"
import Helpers from "../../utils/Helpers"

interface Props {
  children: React.ReactNode
}

type Modal = ModalType | undefined

export const ModalRoute = (props: Props) => {
  const { children } = props

  const [modal, setModal] = useState<Modal>(undefined)
  const showModal = useShowModal(modal)

  useEffect(() => {
    if (!modal) {
      const query = Helpers.extractQueryFromUrl("modal") as Modal

      if (query && modalTypeArray.includes(query)) {
        setModal(query)
      }
    } else {
      showModal()
    }
  }, [modal])

  return <>{children}</>
}

export default ModalRoute
