import React from "react"
import styled from "styled-components"

import * as TextStyles from "../styles/TextStyles"
import LoaderAnimation from "../animations/LoaderAnimation"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string
  isLoading?: boolean
}

const FormButton = (props: Props) => {
  const { title, isLoading, ...buttonAttributes } = props

  return isLoading ? (
    <Wrapper {...buttonAttributes}>
      <LoaderAnimation />
    </Wrapper>
  ) : (
    <Wrapper {...buttonAttributes}>
      <Title>{title}</Title>
    </Wrapper>
  )
}

export default FormButton

const Wrapper = styled.button`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.6) 100%);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 10px;
  padding: 5px 44px;
  cursor: pointer;

  &,
  * {
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :focus {
    outline: none;
  }

  :hover {
    transform: scale(1.05);
  }
`

const Title = styled(TextStyles.MediumText)`
  color: #8338a8;
  font-weight: bold;
  font-size: 16px;
`
