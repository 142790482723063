import { User } from "firebase/auth"

import { CurrentUser, DisplayName, PhotoUrl } from "../../types/authTypes"
import { ContextStatus, initialContextStatus } from "../../types/statusTypes"

export interface State {
  isAuthLoading: boolean
  authUser: User | null
  currentUser: CurrentUser | null
  isUserLoggedIn: boolean
  isEmailVerified: boolean
  displayName: DisplayName
  photoUrl: PhotoUrl
  status: ContextStatus
}

export enum Actions {
  setUser = "SET_USER",
  updateProfile = "UPDATE_PROFILE",
  setStatus = "SET_STATUS",
}

interface Action {
  type: Actions
  payload: Partial<State>
}

export const initialState: State = {
  isAuthLoading: true,
  authUser: null,
  currentUser: null,
  isUserLoggedIn: false,
  isEmailVerified: false,
  displayName: null,
  photoUrl: null,
  status: initialContextStatus,
}

const authReducer = (state: State, action: Action): State => {
  const { type, payload } = action

  switch (type) {
    case Actions.setUser:
      //   console.log("SET_USER", payload)

      return {
        ...state,
        isAuthLoading: false,
        authUser: payload.authUser!,
        currentUser: payload.currentUser!,
        isUserLoggedIn: payload.isUserLoggedIn!,
        isEmailVerified: payload.authUser ? payload.authUser.emailVerified : false,
        displayName: payload.authUser ? payload.authUser.displayName : null,
        photoUrl: payload.authUser ? payload.authUser.photoURL : null,
      }

    case Actions.updateProfile:
      //   console.log("UPDATE_PROFILE", payload)

      // Passing authUser to exclude undefined possibility when not updating a field
      return {
        ...state,
        displayName: payload.authUser ? payload.authUser.displayName : null,
        photoUrl: payload.authUser ? payload.authUser.photoURL : null,
      }

    case Actions.setStatus:
      //   console.log("SET_STATUS", payload)

      return {
        ...state,
        status: payload.status!,
      }

    default:
      throw new Error(`No case for type ${type} found in authReducer.`)
  }
}

export default authReducer
