import React, { useState } from "react"
import styled from "styled-components"

import PurpleButton from "../components/buttons/PurpleButton"

interface Props {
  compositionList: any
  index: number
}

const CompositionModal = (props: Props) => {
  const { compositionList, index } = props
  const [currentIndex, setCurrentIndex] = useState(index)

  const PrevComp = () => {
    if (currentIndex <= 0) {
      setCurrentIndex(compositionList?.length - 1)
    } else {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const NextComp = () => {
    if (currentIndex >= compositionList?.length - 1) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(currentIndex + 1)
    }
  }

  return (
    <Wrapper>
      <Image
        src={`${compositionList?.[currentIndex]?.image?.file?.url}?fm=webp`}
        alt={compositionList?.[currentIndex]?.image?.title}
      />
      <ButtonContainer>
        <PurpleButton
          onClick={() => PrevComp()}
          icon="/images/icons/chevron-left.svg"
          isLeft={true}
        />
        <PurpleButton
          onClick={() => NextComp()}
          icon="/images/icons/chevron-right.svg"
          isLeft={false}
        />
      </ButtonContainer>
    </Wrapper>
  )
}

export default CompositionModal

const Wrapper = styled.div`
  position: relative;
  display: grid;
`

const Image = styled.img`
  max-width: 1234px;
  width: 100%;
  margin: 0 auto;
  object-fit: contain;
  border-radius: 20px;
  box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.15);
`

const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  top: 50%;
  justify-content: space-between;
  margin: -40px auto 0;
`
