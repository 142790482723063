class Helpers {
  static extractQueryFromUrl(queryString: string, urlString?: string) {
    const url = new URL(urlString || window.location.href)
    const results = url.searchParams.getAll(queryString)
    return results.length > 1 ? results[results.length - 1] : results[0]
  }

  static formatToLocaleDateString(
    date: Date | string,
    options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  ) {
    if (typeof date === "string") {
      date = new Date(Date.parse(date))
    }

    return date.toLocaleDateString("en-US", options)
  }

  // Group array of objects by property
  static groupArrayByProperty(list: any[], key: string) {
    return list.reduce(function (rv, x) {
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  static sortArrayByProperty(array: any[], key: string) {
    return array.sort((a, b) => {
      const aProperty = a[key]
      const bProperty = b[key]
      return aProperty > bProperty ? 1 : bProperty > aProperty ? -1 : 0
    })
  }
}

export default Helpers
