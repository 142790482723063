import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import { Wrapper } from "./SignInModal"
import * as TextStyles from "../components/styles/TextStyles"
import PillButton from "../components/buttons/PillButton"
import { useDismissModal } from "../states/context/ModalContext"

interface Props {
  logLevel?: "info" | "success" | "error"
  message?: string
}

const MessageModal = (props: Props) => {
  const { logLevel = "info", message } = props

  const dismissModal = useDismissModal()

  let title: string = "Message"
  let icon: string = "/images/icons/mail.png"

  if (logLevel === "success") {
    title = "Success"
    icon = "/images/icons/mail.png"
  }

  if (logLevel === "error") {
    title = "Error"
    icon = "/images/icons/mail.png"
  }

  return (
    <Container>
      <Icon src={icon} alt="icon" />
      <Title>{title}</Title>
      <Description>{message}</Description>
      <ButtonWrapper>
        <PillButton
          icon="/images/icons/arrow.svg"
          title="Back to Homepage"
          onClick={dismissModal}
        />
      </ButtonWrapper>
    </Container>
  )
}

export default MessageModal

const Container = styled(Wrapper)`
  height: fit-content;
`

const Icon = styled.img`
  width: 96px;
  height: 96px;
  margin: 0 auto;
`

const Title = styled(TextStyles.H4)`
  color: #ffffff;
`

const Description = styled(TextStyles.MediumText)`
  color: #ffffff;
  max-width: 440px;
`

const ButtonWrapper = styled.div`
  max-width: 260px;
  margin: 0 auto;
`
