import React, { ReactNode, createContext, useContext, useReducer, useEffect } from "react"
import { navigate } from "gatsby"

import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as authSignOut,
  sendEmailVerification as authSendEmailVerification,
  sendPasswordResetEmail as authSendPasswordResetEmail,
  deleteUser as authDeleteUser,
  updateProfile as authUpdateProfile,
  ActionCodeSettings,
  // connectAuthEmulator,
} from "firebase/auth"

import firebaseApp from "../../utils/firebase"
import config from "../../utils/config"
import authReducer, { initialState, State, Actions } from "./authReducer"

import { CurrentUser, DisplayName, PhotoUrl } from "../../types/authTypes"
import { ContextStatus, initialContextStatus } from "../../types/statusTypes"

type ContextProps = {
  children: ReactNode
}

type ContextValue = State & {
  signUpWithPassword: (email: string, password: string) => void
  signInWithPassword: (email: string, password: string) => void
  signOut: () => void
  sendPasswordResetEmail: (email: string) => void
  sendEmailVerification: () => void
  deleteUser: () => void
  updateProfile: (displayName: string) => void
}

// const emulatorUrl = "http://localhost:9099"

const AuthContext = createContext<ContextValue | undefined>(undefined)

export function AuthProvider({ children }: ContextProps) {
  const [state, dispatch] = useReducer(authReducer, initialState)

  // Set a continue URL to redirect user after clicking on link in email
  const actionCodeSettings: ActionCodeSettings = {
    url: `${config.baseUrl}`,
  }

  useEffect(() => {
    const auth = getAuth(firebaseApp)

    // process.env.NODE_ENV === "staging" && connectAuthEmulator(auth, emulatorUrl)

    // Firebase authentication state observer to determine if auth user has changed
    onAuthStateChanged(auth, authUser => {
      if (authUser) {
        // User is signed in
        const currentUser: CurrentUser = {
          uid: authUser.uid,
          email: authUser.email!,
        }

        dispatch({
          type: Actions.setUser,
          payload: {
            authUser,
            currentUser,
            isUserLoggedIn: true,
          },
        })
      } else {
        // User is signed out
        dispatch({
          type: Actions.setUser,
          payload: {
            authUser: null,
            currentUser: null,
            isUserLoggedIn: false,
          },
        })
      }
    })
  }, [])

  const signUpWithPassword = async (email: string, password: string) => {
    // console.log("Signing up")

    setStatus({
      state: "pending",
    })

    try {
      const auth = getAuth()
      // process.env.NODE_ENV === "staging" && connectAuthEmulator(auth, emulatorUrl)

      const userCredential = await createUserWithEmailAndPassword(auth, email, password)

      if (userCredential.user) {
        await authSendEmailVerification(userCredential.user, actionCodeSettings)
      }

      setStatus({
        state: "resolved",
        message:
          "You have signed up successfully. Please check your inbox to verify your email address.",
      })
    } catch (error: any) {
      setStatus({
        state: "rejected",
        error: error.message,
        showMessage: true,
      })
    } finally {
      resetStatus()
    }
  }

  const signInWithPassword = async (email: string, password: string) => {
    // console.log("Signing in")

    setStatus({
      state: "pending",
    })

    try {
      const auth = getAuth()
      // process.env.NODE_ENV === "staging" && connectAuthEmulator(auth, emulatorUrl)

      await signInWithEmailAndPassword(auth, email, password)

      setStatus({
        state: "resolved",
        message: "You are now logged in.",
      })
    } catch (error: any) {
      const message = error.code.split("/").pop().replaceAll("-", " ")
      const formattedMessage = "Error: " + message.charAt(0).toUpperCase() + message.slice(1)

      setStatus({
        state: "rejected",
        error: formattedMessage || error.code || error,
        showMessage: true,
      })
    } finally {
      resetStatus()
    }
  }

  const signOut = async () => {
    // console.log("Signing out")

    const auth = getAuth()
    // process.env.NODE_ENV === "staging" && connectAuthEmulator(auth, emulatorUrl)

    if (!auth.currentUser) throw new Error("signOut cannot be used without a currentUser")

    setStatus({
      state: "pending",
    })

    try {
      await authSignOut(auth)
      navigate("/")

      setStatus({
        state: "resolved",
        message: "You've successfully logged out.",
      })
    } catch (error: any) {
      setStatus({
        state: "rejected",
        error: error.message,
        showMessage: true,
      })
    } finally {
      resetStatus()
    }
  }

  const sendPasswordResetEmail = async (email: string) => {
    // console.log("Sending reset password email")

    setStatus({
      state: "pending",
    })

    try {
      const auth = getAuth()
      // process.env.NODE_ENV === "staging" && connectAuthEmulator(auth, emulatorUrl)

      await authSendPasswordResetEmail(auth, email, actionCodeSettings)

      setStatus({
        state: "resolved",
        message: "Password reset email sent! Check your inbox.",
      })
    } catch (error: any) {
      setStatus({
        state: "rejected",
        error: error.message,
        showMessage: true,
      })
    } finally {
      resetStatus()
    }
  }

  const sendEmailVerification = async () => {
    // console.log("Sending email with verification link")

    const auth = getAuth()
    // process.env.NODE_ENV === "staging" && connectAuthEmulator(auth, emulatorUrl)

    const authUser = auth.currentUser

    if (!authUser) throw new Error("sendEmailVerification cannot be used without a currentUser")

    setStatus({
      state: "pending",
    })

    try {
      await authSendEmailVerification(authUser, actionCodeSettings)

      setStatus({
        state: "resolved",
        message:
          "We've just sent you an email to verify your email address. Please check your inbox.",
        showMessage: true,
      })
    } catch (error: any) {
      setStatus({
        state: "rejected",
        error: error.message,
        showMessage: true,
      })
    } finally {
      resetStatus()
    }
  }

  const deleteUser = async () => {
    // console.log("Deleting user")

    const auth = getAuth()
    // process.env.NODE_ENV === "staging" && connectAuthEmulator(auth, emulatorUrl)
    const authUser = auth.currentUser

    if (!authUser) throw new Error("deleteUser cannot be used without a currentUser")

    setStatus({
      state: "pending",
    })

    try {
      // await auth.currentUser?.delete()
      await authDeleteUser(authUser)

      setStatus({
        state: "resolved",
        message: "Your account has been deleted.",
        showMessage: true,
      })
    } catch (error: any) {
      setStatus({
        state: "rejected",
        error: error.message,
        showMessage: true,
      })
    } finally {
      resetStatus()
    }
  }

  const updateProfile = async (displayName?: DisplayName, photoURL?: PhotoUrl) => {
    const auth = getAuth()
    // process.env.NODE_ENV === "staging" && connectAuthEmulator(auth, emulatorUrl)
    const authUser = auth.currentUser

    if (!authUser) throw new Error("updateAuthProfile cannot be used without a currentUser")

    if (!displayName || !photoURL)
      throw new Error("updateAuthProfile cannot be used without a displayName or photoURL")

    setStatus({
      state: "pending",
    })

    try {
      await authUpdateProfile(authUser, { displayName, photoURL })

      dispatch({
        type: Actions.updateProfile,
        payload: { authUser },
      })

      setStatus({
        state: "resolved",
        message: "Your profile has been updated.",
      })
    } catch (error: any) {
      setStatus({
        state: "rejected",
        error: error.message,
      })
    } finally {
      resetStatus()
    }
  }

  const resetStatus = () => {
    setStatus(initialContextStatus)
  }

  const setStatus = (status: ContextStatus) => {
    const { state, message, error, showMessage } = status

    if (state === "resolved") {
      // console.log(message)
    }

    if (state === "rejected") {
      // console.log(error)
    }

    dispatch({
      type: Actions.setStatus,
      payload: {
        status: {
          state,
          message: message || null,
          error: error || null,
          isPending: state === "pending",
          isResolved: state === "resolved",
          isRejected: state === "rejected",
          showMessage: showMessage || false,
        },
      },
    })
  }

  const value = {
    isAuthLoading: state.isAuthLoading,
    authUser: state.authUser,
    currentUser: state.currentUser,
    isUserLoggedIn: state.isUserLoggedIn,
    isEmailVerified: state.isEmailVerified,
    displayName: state.displayName,
    photoUrl: state.photoUrl,
    status: state.status,
    signUpWithPassword,
    signInWithPassword,
    signOut,
    sendPasswordResetEmail,
    sendEmailVerification,
    deleteUser,
    updateProfile,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

// Custom hook to use AuthContext
const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider")
  }

  return context
}

export default useAuth
