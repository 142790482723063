import React from "react"
import styled from "styled-components"

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  icon: string
}

interface StyledProps {
  src: string
  alt: string
}

const FormInput = (props: Props) => {
  const { type, icon } = props

  return (
    <Wrapper>
      <label htmlFor={type}>
        <IconWrapper className="icon-wrapper">
          <Icon className="icon" src={icon} alt={`${type} icon`} />
        </IconWrapper>
        <Input {...props} name={type} />
      </label>
    </Wrapper>
  )
}

export default FormInput

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 52px;

  &,
  * {
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :focus-within {
    .icon-wrapper {
      border: 1px solid rgba(131, 56, 168, 1);
      box-shadow: 0px 0px 8px 1px rgba(131, 56, 168, 0.2);
    }

    .icon {
      background: rgba(131, 56, 168, 1);
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
    transition: background-color 5000s ease-in-out 0s;
  }
`

const IconWrapper = styled.div`
  background: rgba(255, 255, 255, 0.4);
  border-radius: 14px;
  border: 1px solid rgba(255, 255, 255, 1);
  position: absolute;
  top: 8px;
  left: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

const Icon = styled.div<StyledProps>`
  margin: auto;
  width: 24px;
  height: 24px;
  mask-image: url(${props => props.src || "/images/icons/email-black.svg"});
  mask-repeat: no-repeat;
  mask-position: center;
  background: rgba(0, 0, 0, 0.6);
`

const Input = styled.input`
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  border: none;
  box-shadow: 0px 30px 30px rgba(69, 42, 124, 0.15);
  backdrop-filter: blur(60px);
  border-radius: 20px;
  padding-left: 56px;
  padding-right: 56px;

  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 130%;
  color: rgba(0, 0, 0, 0.7);

  :focus {
    background: rgba(255, 255, 255, 0.8);
  }

  &:focus {
    outline: none;
    padding-left: 56px;
    box-shadow: 0px 10px 40px rgba(131, 56, 168, 0.3), inset 0px 0px 0px 1px #8338a8;
  }
`
