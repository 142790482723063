import { useEffect, useReducer } from "react"

import { ContextStatus } from "../../types/statusTypes"
import config from "../config"

export enum FunctionsEndpoint {
  accountStatus = "accountStatus",
  changePassword = "changePassword",
  stripeCheckoutCreate = "stripeCheckoutCreate",
  paypalCheckoutCompleted = "paypalCheckoutCompleted",
  downloadLinksGet = "downloadLinksGet",
  getDiscountCode = "getDiscountCode",
}

export interface State extends ContextStatus {
  response: any
}

type Action =
  | { type: "setPending"; payload?: any }
  | { type: "setResolved"; payload: { response: any } }
  | { type: "setRejected"; payload: { error: string } }

export const initialState: State = {
  state: "idle",
  message: null,
  error: null,
  response: null,
}

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "Content-Type, Origin",
  "Content-Type": "application/json; charset=utf-8",
}

// TO DO: Integrate abort controller
const useFetchFunctions = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  // const cache = useRef<any>({})

  useEffect(() => {}, [])

  // TO DO: useCallback to refetch on certain dependencies?
  const fetchFunctions = async (endpoint: FunctionsEndpoint, args: any) => {
    // if (!endpoint) return

    dispatch({ type: "setPending" })

    const body = JSON.stringify(args)

    const options: RequestInit = {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers,
      body,
    }

    const url = `${config.firebaseFunctionsUrl}/${endpoint}`

    // if (cache.current[url]) {
    // dispatch({ type: "setResolved", payload: { response: cache.current[url] } })
    // } else {
    //   // Add code below
    // }

    try {
      const res = await fetch(url, options)
      const json = await res.json()

      if (res.ok) {
        // console.log("statusText is OK")

        // cache.current[url] = json
        dispatch({ type: "setResolved", payload: { response: json } })
        return json
      } else {
        // console.log("statusText is not OK")
        console.error("Error", json)
        dispatch({ type: "setRejected", payload: { error: json } })
        return json
      }
    } catch (error: any) {
      // console.log("Fetch catch block")
      console.error("Error in catch block", error)
      dispatch({ type: "setRejected", payload: { error } })
      return { error }
    }
  }

  return { state, fetchFunctions }
}

export default useFetchFunctions

const reducer = (state: State, action: Action): State => {
  const { type, payload } = action

  switch (type) {
    case "setPending":
      return {
        ...state,
        state: "pending",
      }

    case "setResolved":
      return {
        ...state,
        state: "resolved",
        response: payload.response,
      }

    case "setRejected":
      return {
        ...state,
        state: "rejected",
        error: payload.error,
      }

    default:
      return state
  }
}
