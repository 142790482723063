import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { useShowModal, useDismissModal } from "../states/context/ModalContext"
import useAuth from "../states/context/AuthContext"
import FormInput from "../components/inputs/FormInput"
import useInput from "../utils/hooks/useInput"
import FormButton from "../components/buttons/FormButton"
import CloseButton from "../components/buttons/CloseButton"

import {
  Wrapper,
  Container,
  TextContainer,
  Logo,
  Title,
  Title2,
  Caption,
  FormContainer,
  InputContainer,
  CloseButtonContainer,
} from "./SignInModal"
import ErrorBubble from "../components/other/ErrorBubble"

const ResetPasswordModal = () => {
  const showSignInModal = useShowModal("signIn")
  const dismiss = useDismissModal()
  const { status, sendPasswordResetEmail, currentUser } = useAuth()
  const [error, setError] = useState("")

  const { value: email, bind: bindEmail } = useInput(currentUser ? currentUser.email : "")

  const handleResetPassword = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    sendPasswordResetEmail(email)
  }

  if (status.isResolved) {
    dismiss()
  }

  useEffect(() => {
    if (status.isRejected) {
      status.error && setError(status.error)
    }
  }, [status])

  return (
    <Wrapper>
      <CloseButtonContainer>
        <CloseButton />
      </CloseButtonContainer>
      {error !== "" && <ErrorBubble error={error} />}
      <Container>
        <Logo src="/images/logos/logo.svg" alt="angle logo" />
        <StaticImage
          className="illustration"
          src="../images/illustrations/illustration1.webp"
          alt="illustration"
        />
        <Title>Reset Password</Title>
      </Container>
      <FormContainer onSubmit={handleResetPassword}>
        <InputContainer>
          <Title2>Email address</Title2>
          <FormInput
            name="email"
            type="email"
            placeholder="Email address"
            icon="/images/icons/envelope.svg"
            autoComplete="on"
            required
            {...bindEmail}
          />
        </InputContainer>
        <ButtonContainer>
          <FormButton
            title="Reset Password"
            type="submit"
            isLoading={status.isPending}
            disabled={status.isPending}
          />
        </ButtonContainer>
      </FormContainer>
      <TextContainer>
        <Caption>
          Enter the email address associated to your Angle account. We will send you a link to reset
          your password.
        </Caption>
        <Caption>
          Back to <span onClick={showSignInModal}>Sign In</span>
        </Caption>
      </TextContainer>
    </Wrapper>
  )
}

export default ResetPasswordModal

const ButtonContainer = styled.div`
  max-width: 220px;
`
