import React, { createElement, useRef } from "react"
import styled, { keyframes } from "styled-components"

import { useDismissModal } from "../states/context/ModalContext"
import useKeyPress from "../utils/hooks/useKeyPress"

import SignInModal from "./SignInModal"
import SignUpModal from "./SignUpModal"
import MessageModal from "./MessageModal"
import ResetPasswordModal from "./ResetPasswordModal"
import CompositionModal from "./CompositionModal"

const modalType = {
  signIn: SignInModal,
  signUp: SignUpModal,
  message: MessageModal,
  resetPassword: ResetPasswordModal,
  composition: CompositionModal,
}

export type ModalType = keyof typeof modalType
export const modalTypeArray: string[] = []

Object.keys(modalType).forEach(value => {
  modalTypeArray.push(value)
})

interface ModalProps {
  isVisible: boolean
  type: ModalType
  props?: any
}

const Modal = (modalProps: ModalProps) => {
  const { isVisible, type, ...props } = modalProps
  const modal = modalType[type]
  if (!modal) return null

  let currentModal = createElement(modal, props)
  const modalBackground = useRef<HTMLDivElement>(null)

  const dismiss = useDismissModal()

  // Dismiss on escape key press
  useKeyPress("escape") && dismiss()

  // Dismiss on background click
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.target === modalBackground.current) dismiss()
  }

  return isVisible ? (
    <ModalBackground ref={modalBackground} onClick={handleClick}>
      {currentModal}
    </ModalBackground>
  ) : null
}

export default Modal

const animation = keyframes`
  from { backdrop-filter: blur(0px) saturate(100%); }
  to { backdrop-filter: blur(20px) saturate(120%); }
`

export const ModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px) saturate(100%);
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding: 0 20px;
  animation: ${animation} 1s forwards;
`

export const ButtonWrapper = styled.div`
  position: absolute;
  top: -18px;
  right: -18px;

  @media (max-width: 900px) {
    opacity: 0;
  }
`
