import { useState, useEffect } from "react"

const useKeyPress = (key: string) => {
  const [keyPressed, setKeyPressed] = useState(false)

  const match = (event: KeyboardEvent) =>
    event.key && event.key.toLowerCase() === key.toLowerCase()

  const onPress = (event: KeyboardEvent) => {
    if (match(event)) setKeyPressed(true)
  }

  const onRelease = (event: KeyboardEvent) => {
    if (match(event)) setKeyPressed(false)
  }

  useEffect(() => {
    window.addEventListener("keydown", onPress)
    window.addEventListener("keyup", onRelease)

    return () => {
      window.removeEventListener("keydown", onPress)
      window.removeEventListener("keyup", onRelease)
    }
  })

  return keyPressed
}

export default useKeyPress
