export interface ContextStatus {
  state: keyof typeof ContextState
  isPending?: boolean
  isResolved?: boolean
  isRejected?: boolean
  message?: string | null
  error?: string | null
  showMessage?: boolean
}

export const initialContextStatus: ContextStatus = {
  state: "idle",
  isPending: false,
  isResolved: false,
  isRejected: false,
  message: null,
  error: null,
  showMessage: false,
}

export enum ContextState {
  idle,
  pending,
  resolved,
  rejected,
}
